import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './app/store/index'
import setupInterceptors from './app/services/setupInterceptors';

// Updated way to create root and render
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

setupInterceptors(store);
serviceWorker.unregister();
